import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("@/layouts/index.vue"),
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/home/index.vue"),
      },
      {
        path: "/connect",
        name: "connect",
        component: () => import("@/views/connect/index.vue"),
      },
      {
        path: "/product/smartProduct",
        name: "smartProduct",
        component: () => import("@/views/product/smartProduct.vue"),
      },
      {
        path: "/product/smartPart",
        name: "smartPart",
        component: () => import("@/views/product/smartPart.vue"),
      },
      {
        path: "/product/smartCommunity",
        name: "smartCommunity",
        component: () => import("@/views/product/smartCommunity.vue"),
      },
      {
        path: "/news",
        name: "news",
        component: () => import("@/views/news/center.vue"),
      },
      {
        path: "/news/detail",
        name: "newsDetail",
        component: () => import("@/views/news/detail.vue"),
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        component: () => import("@/views/aboutUs/index.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // 始终回到顶部
    return { top: 0 };
  },
});

export default router;
